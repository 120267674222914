import React from "react";

const CheckboxField = (request) => {
    let props = request.request;
    let column = props.column;
    return (<>
        <input
            className="form-check-input"
            type={props.column.inputType}
            onChange={props.onChange}
            id={props.column.fieldName}
            checked={props.checked}
            disabled={props.column.disabled}
            defaultChecked={props.defaultChecked} />
        </>)
}


export default CheckboxField;

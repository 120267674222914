export const FormFieldTypes = {
    Input: 1,
    Select: 2,
    CheckBox: 3,
    Numeric: 4,
    textarea: 5,
    DatePicker: 6,
    Password: 7,
    Currency: 8
}

export const ButtonTypes = {
    Primary: 1,
    Secondary: 2,
    Cancel: 3
}
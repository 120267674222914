/* Component  */
import { axiosGet } from '../AxiosRequests';
import { Admin_GetProductDashboardCounts } from '../ApiEndPoints';

export default class ProductDashServices {
    // API function

    async admin_GetProductDashboardCounts(request) {
        return axiosGet(Admin_GetProductDashboardCounts, request);
    }
}

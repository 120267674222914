import { AddDatabaseHealthWizard, DeleteDatabaseHealthWizard, ExportDatabaseHealthWizardOutput, GetDatabaseHealthWizardById, GetDatabaseHealthWizardList, GetDatabaseHealthWizardOutput, UpdateDatabaseHealthWizard } from "../ApiEndPoints";
import { axiosGet, axiosGetMultiParamsFileAuthorizeblob, axiosPost } from "../AxiosRequests";

export default class DatabaseHealthServices {
    async addDatabaseHealthWizard(request) {
        return axiosPost(AddDatabaseHealthWizard, request);
    }
    async updateDatabaseHealthWizard(request) {
        return axiosPost(UpdateDatabaseHealthWizard, request);
    }
    async deleteDatabaseHealthWizard(request) {
        return axiosPost(DeleteDatabaseHealthWizard, request);
    }
    async getDatabaseHealthWizardList(request) {
        return axiosGet(GetDatabaseHealthWizardList, request);
    }
    async getDatabaseHealthWizardById(request) {
        return axiosGet(GetDatabaseHealthWizardById, request);
    }
    async getDatabaseHealthWizardOutput(request) {
        return axiosGet(GetDatabaseHealthWizardOutput, request);
    }
    async exportDatabaseHealthWizardOutput(request) {
        return axiosGetMultiParamsFileAuthorizeblob(ExportDatabaseHealthWizardOutput, request);
    }

}
/* Component  */
import { axiosPostAuthorize,axiosGetAuthorize } from '../AxiosRequests';
import { Admin_AddDigestEmailTemplate, Admin_GetDigestEmailList, Admin_GetDigestEmailTemplateById, Admin_UpdateDigestEmailDetailsById ,Admin_GetQueryParameters} from '../ApiEndPoints';


export default class DigestEmailServices {
// API function

    async admin_GetDigestEmailList(request) {
        
        return axiosPostAuthorize(Admin_GetDigestEmailList, request);

    }
    async admin_GetDigestEmailTemplateById(request) {
   
        return axiosGetAuthorize(Admin_GetDigestEmailTemplateById, request);

    }

    async admin_UpdateDigestEmailDetailsById(request) {
        
        return axiosPostAuthorize(Admin_UpdateDigestEmailDetailsById, request);

    }

    async admin_AddDigestEmailTemplate(request) {
     
        return axiosPostAuthorize(Admin_AddDigestEmailTemplate, request)

    }
    async admin_GetQueryParameters(request) {
   
        return axiosGetAuthorize(Admin_GetQueryParameters, request);

    }
}
/* eslint-disable default-case */
import BaseDomainService from "./baseDomainServices";
import { ErrorMessage, SuccessMessage } from '../../utils/Messages';
import { getValidationRuleList } from '../../utils/validation/generateValidationRule';

const requestPropertyDetail = {
    mf: "",
    mw: "",
    rotBonds: "",
    hdonor: "",
    hacceptor: "",
    polarSA: ""
};
export default class ProductPropertyDomainService extends BaseDomainService {

    getProperty = this.apiService.productService.getProductPropertyById;
    updateProductProperties = this.apiService.productService.admin_UpdateProductPropertiesById;
    addProductProperties = this.apiService.productService.admin_AddProductPropertiesById;

    gridColmnsForProperty = [
        { name: "mf", title: "Mol. Formula" },
        { name: "mw", title: " Mol. Weight" },
        { name: "rotBonds", title: "Rot. Bonds" },
        { name: "hacceptor", title: " H Acceptor" },
        { name: "hdonor", title: " H Donor" },
        { name: "polarSA", title: " Polar SA" },
        { name: "productPropertiesId", title: " Action" },
    ]

    productPropertyForm = [
        {
            inputType: "text",
            tittle: "Mol. Formula",
            placeholder: "Mol. Formula",
            fieldName: "mf",
            className: 'w-full',
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Mol. Weight",
            placeholder: "Mol. Weight",
            fieldName: "mw",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Rot. Bonds",
            placeholder: "Rot. Bonds",
            fieldName: "rotBonds",
            pattern: "[0-9]*",
            className: 'w-full',
            onkeydown: (event) => {
                if (event.key == "." || event.key === "-" || event.key === "+" || event.key === "e" || event.key === "E") {
                    event.preventDefault()
                }
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(event.key) && event.preventDefault();
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "H Acceptor",
            placeholder: "H Acceptor",
            fieldName: "hacceptor",
            className: 'w-full',
            onkeydown: (event) => {
                if (event.key == "." || event.key === "-" || event.key === "+" || event.key === "e" || event.key === "E") {
                    event.preventDefault()
                }
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(event.key) && event.preventDefault();
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "H Donor",
            placeholder: "H Donor",
            fieldName: "hdonor",
            className: 'w-full',
            onkeydown: (event) => {
                if (event.key == "." || event.key === "-" || event.key === "+" || event.key === "e" || event.key === "E") {
                    event.preventDefault()
                }
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(event.key) && event.preventDefault();
            },
            validation: [{ type: "require" }],
        },
        {
            inputType: "text",
            tittle: "Polar SA",
            placeholder: "Polar SA",
            fieldName: "polarSA",
            className: 'w-full',
            onkeydown: (e) => {
                /^[\s\a-zA-Z,\b][0-9,\b]*$/.test(e.key) && e.preventDefault()
            },
            validation: [{ type: "require" }],
        },
    ]

    dataPropertyItem;
    constructor() {
        super();
        this.actionKey = "productPropertiesId";
        this.onPaginationUpdate = this.onGetProperty.bind(this);
        this.dataPropertyItem = { ...requestPropertyDetail };

    }
    onGetProperty = (requst) => {
        this.apiService.onAPICall("getProperty", this.getProperty, requst, this.onAPIResponse, this.onUpdateState);
    }
    onUpdateProductProperties = (requst, getRequest) => {
        this.apiService.onAPICall("updateProductProperties", this.updateProductProperties, requst, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onAddProductProperties = (request, getRequest) => {
        this.apiService.onAPICall("addProductProperties", this.addProductProperties, request, this.onAPIResponse, this.onUpdateState, getRequest);
    }
    onInitPropertyService() {
        //This method only call when we use form component and we can add all items which we on on form load 
        let valRule = getValidationRuleList(this.productPropertyForm)
        this.validationRules = valRule;
        this.onUpdateState({
            "validationRules": valRule,
            "resetValidationRule": this.resetValidationRule
        })
    }
    onResetData = () => {
        this.dataPropertyItem = { ...requestPropertyDetail }
        let resetValidation = {
            isValid: false,
            error: {}
        }
        this.onUpdateState({
            updatePS: this.dataPropertyItem,
            product: this.dataPropertyItem,
            validState: resetValidation
        });
    }

    onAPIResponse = (key, isSuccess, responseData, getRequest) => {
        if (!isSuccess) {
            this.onUpdateState({ "setIsLoad": false });
            return;
        }
        switch (key) {
            case "getProperty":
                let data = [];
                data.push(responseData);
                if (responseData != null) {
                    this.onUpdateState({
                        "productPropertiesData": data,
                        "TotalCount": responseData.totalCount,
                    });
                }
                this.onUpdateState({ "setIsLoad": false })
                break;

            case "getProduct":
                this.onUpdateState({
                    "dataList": responseData.itemList,
                    "TotalCount": responseData.totalCount,
                    "setIsLoad": false
                });
                break;

            case "addProductProperties":
                this.apiService.serviceManager.swalServices.Success(SuccessMessage.Insert_Success.replace("{0}", "Product Properties"));
                this.onUpdateState({
                    "pageLoader": false,
                    "setOpenModal": false
                });
                this.onGetProperty(getRequest)
                break;

            case "updateProductProperties":
                if (responseData.errorType === "EXISTS" && responseData.hasError === true) {
                    this.apiService.serviceManager.swalServices.Toaster(ErrorMessage.DefaultMessage);
                }
                else {
                    this.onUpdateState({
                        "isBtnLoading": false,
                        "setOpenModal": false
                    });
                    this.onGetProperty(getRequest)
                    this.apiService.serviceManager.swalServices.Success(SuccessMessage.Update_Success.replace("{0}", "Product Properties"));
                }
                break;
        }
    }
}

//Component

import React from "react";

//*** LIb */

import { Editor } from "@tinymce/tinymce-react";

function TinyEceEditor({ handleChange, ...props }) {
  const custom_config = {
    toolbar: {
      items: [
        "heading",
        "|",
        "bold",
        "italic",
        "link",
        "bulletedList",
        "numberedList",
        "|",
        "blockQuote",
        "insertTable",
        "|",
        "undo",
        "redo",
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
  };

  return (
    <div className="tiny-editor-sec" key={props && props.key ? `${props.key}div` : "TinyEceEditor"}>
      <Editor
        value={props.data}
        apiKey="gx6gk77kiq15c2bqi8nvjsryhozb87ear8xhnw71iajw2b1t"

        onEditorChange={(newText, editor) => props.onChange(newText, editor)}
        {...props}
        init={{

          plugins: "link image code lists wordcount table",
          toolbar:
            "undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist checklist outdent indent | wordcount | table ",
          statusbar: false,
          visual: false,
          content_style: '#tinymce{color:#757575;} table tr th{padding:5px;}',
          font_family_formats: 'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats;',

        }}
      />
    </div>
  );
}

export default TinyEceEditor;
